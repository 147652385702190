/*=========================================================================================
  File Name: progress.scss
  Description: Extended bootstrap progress bar scss.
  ----------------------------------------------------------------------------------------
  Item Name: Csign - Phần mềm ký số từ xa
  Author: hung-hust-97
  Author URL: https://github.com/hung-hust-97
  ----------------------------------------------------------------------------------------
==========================================================================================*/

.progress {
  // border radius to set for stacked bars
  .progress-bar + .progress-bar:not(:last-child) {
    border-radius: 0;
  }
  .progress-bar:last-child {
    border-top-right-radius: $progress-border-radius;
    border-bottom-right-radius: $progress-border-radius;
  }
}
