@import "@core/scss/core.scss";
@import "./assets/scss/styles";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

@font-face {
  font-family: "arial";
  src: url("./assets/fonts/font-family/arial.woff2") format("woff2");
}

@font-face {
  font-family: "arial bold";
  src: url("./assets/fonts/font-family/arial-bold.woff2") format("woff2");
}

@font-face {
  font-family: "arial italic";
  src: url("./assets/fonts/font-family/arial-italic.woff2") format("woff2");
}

@font-face {
  font-family: "times new roman";
  src: url("./assets/fonts/font-family/times-new-roman.woff2") format("woff2");
}

@font-face {
  font-family: "times new roman bold";
  src: url("./assets/fonts/font-family/times-new-roman-bold.woff2")
    format("woff2");
}

@font-face {
  font-family: "lora";
  src: url("./assets/fonts/font-family/lora.woff2") format("woff2");
}

@font-face {
  font-family: "lora bold";
  src: url("./assets/fonts/font-family/lora-bold.woff2") format("woff2");
}

@font-face {
  font-family: "open sans";
  src: url("./assets/fonts/font-family/open-sans.woff2") format("woff2");
}

@font-face {
  font-family: "open sans bold";
  src: url("./assets/fonts/font-family/open-sans-bold.woff2") format("woff2");
}

@font-face {
  font-family: "open sans italic";
  src: url("./assets/fonts/font-family/open-sans-italic.woff2") format("woff2");
}

@font-face {
  font-family: "calibri";
  src: url("./assets/fonts/font-family/calibri.woff2") format("woff2");
}

@font-face {
  font-family: "calibri bold";
  src: url("./assets/fonts/font-family/calibri-bold.woff2") format("woff2");
}
.p-treeselect {
  height: 37.99px;
  width: 100%;
  .p-treeselect-label {
    padding: 0;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
  }
}
.p-treeselect {
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  border-radius: 4px;
}
.p-treeselect .p-treeselect-label {
  padding: 0.5rem 0.75rem;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}

.p-treeselect .p-treeselect-label.p-placeholder {
  color: #6c757d;
}
.p-treeselect .p-treeselect-trigger {
  background: transparent;
  color: #495057;
  width: 2.357rem;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

p-treeselect.ng-invalid.ng-dirty > .p-treeselect {
  border-color: #dc3545;
}
.p-input-filled .p-treeselect {
  background: #efefef;
}
ng2-flatpickr {
  &.is-invalid {
    .ng2-flatpickr-input-container {
      .ng2-flatpickr-input {
        border-color: red !important;
      }
    }
  }
}
.ng-select.is-invalid .ng-select-container {
  border-color: #ff0000;
}
ngx-extended-pdf-viewer .unverified-signature-warning {
  display: none !important;
}

div.cdk-overlay-container {
  z-index: 2000 !important;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
